.title-head-subscribe{
	visibility: visible;
	animation-delay: 0.3s;
	animation-name: fadeInUp;
	font-family: "Game Shark", sans-serif;
	font-size: 50px !important;
}

.text-head-subscribe{
	visibility: visible;
	animation-delay: 0.4s;
	animation-name: fadeInUp;
}
