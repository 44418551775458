/* ##### Partners Area ##### */
.HomeDemo2 .partners{
    padding: 0 0 70px
}

.HomeDemo2 .partner-box img {
    margin-top: 15px;
}

.HomeDemo2 .partner-box{
    font-family: 'Poppins', sans-serif;
    width: 100%;
    border: 1px solid gold;
    border-radius: 0 10px;
    //padding: 20px 10px;
    text-align: center;
    vertical-align: middle;
    background-color: transparent;
    margin-bottom: 30px;
    transition: all .3s ease-in-out;
    padding: 50px;
}

.HomeDemo2 .partner-box h4 {
    color: white !important;
    margin-top: 5px;
}
.HomeDemo2 .partner-box p:hover {
    background: unset;
    font-weight: bold;
}

.HomeDemo2 .partner-box:hover{
    //background: #25cbd3;
    cursor: pointer;
}
