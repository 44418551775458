
/* ##### 13.0 Team Area CSS ##### */
#shark-text {
    font-family: 'Game Shark', sans-serif;
}

.clear-margin-padding {
    margin: 0 !important;
    padding: 0 !important;
}

.HomeDemo2 .single-team-member {
    position: relative;
    z-index: 1;
}

.team-info h5 {
    font-size: 35px !important;
}

.team-info p {
    font-size: 20px !important;
}

.HomeDemo2 .team-member-thumb {
    //width: 190px;
    //height: 190px;
    //border-radius: 50%;
    margin:0 auto;
}

.HomeDemo2 .team-info h5 {
    color: #fff;
    font-family: "Game Shark", sans-serif;
    text-transform: uppercase;
    font-size: 35px !important;
    font-weight: unset !important;
    text-align: center;
    margin-bottom: 0;
}

.HomeDemo2 .team-info p:hover {
    font-weight: bold;
}

.HomeDemo2 .team-info p {
    color: #ccc;
    //text-transform: uppercase;
    font-size: 15px;
    margin-bottom: 0;
    text-align: center;
}

.HomeDemo2 .team-social-icon{
    text-align: center;
}
.HomeDemo2 .team-social-icon a {
    font-size: 25px;
    color: white;
    margin-top: 10px;
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
}

.HomeDemo2 .team-social-icon a:hover {
    color: blue;
}

.HomeDemo2 .team-info h5 {
    font-weight: bold;
}
