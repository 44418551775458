/* ##### About Us Area ##### */

.HomeDemo2 .about-us-area {
    position: relative;
    z-index: 1;
}

.HomeDemo2 .who-we-contant h4 {
    margin-bottom: 20px;
    font-size: 30px;
}

.HomeDemo2 .our-mission-area {
    position: relative;
    z-index: 10;
    width: 100%;
    overflow: hidden;
    height: 400px !important;
}

.HomeDemo2 .our-mission-content {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(72, 52, 212, 0.95);
    background: -webkit-linear-gradient(to right, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
    background: -webkit-linear-gradient(left, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
    background: linear-gradient(to right, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
}

.HomeDemo2 .single-mission {
    position: relative;
    z-index: 1;
    text-align: center;
}

.HomeDemo2 .single-mission i {
    font-size: 50px;
    margin-bottom: 30px;
    display: block;
    color: #fff;
}

.HomeDemo2 .single-mission h6 {
    margin-bottom: 15px;
}

.HomeDemo2 .single-mission p {
    margin-top: 0;
    margin-bottom: 0
}

.HomeDemo2 .welcome-meter {
    position: relative;
    z-index: 1;
}
.HomeDemo2 .welcome-meter.arficial{
    position: relative;
    z-index: 1;
    height: 427px;
    background: url(../../../assets/img/svg/ai-illustration.svg) no-repeat;
    background-size: 137%;
    background-position: center center;
}
@media (max-width: 992px){
    .HomeDemo2 .welcome-meter.arficial{
        background-size: 100%;
    }
}
.HomeDemo2 .growing-company p {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
}

.HomeDemo2 .growing-company p .counter {
    color: #fff;
}