/* ##### demo-video Area CSS ##### */

.HomeDemo2 .demo-video{
    position: relative;
    margin-bottom: 50px;
}

.HomeDemo2 .demo-video .welcome-video-area{
    margin-top: 0
}

@media (max-width: 1280px) {
    .HomeDemo2 .vertical-social{
        display: none !important;
    }
}

.HomeDemo2 .vertical-social:hover {
    border: 15px solid white;
}

.our_team_area {
    margin-bottom: 50px;
}

.HomeDemo2 .vertical-social {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: 30px;
    color: black;
    background: #0E009F;
    opacity: 0.75;
    height: 370px !important;
    margin-left: 30px;
    border-radius: 40px;
    padding: 30px 15px;
    z-index: 999;
    transition: 1s;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    //box-shadow: 0px 4px 13px 0 rgba(168,67,253,.3);
    overflow: hidden;
    //border-bottom: 3px solid white;
}


.HomeDemo2 .vertical-social li{
    padding: 7px 0;
    text-align: center;
}

.HomeDemo2 .vertical-social li a{
    //color: blueviolet;
    //opacity: .6;
    font-size: 22px;
}

.HomeDemo2 .vertical-social li a:hover{
    color: gold;
    opacity: 1;

}
.dream-btn.video-btn{
    background-color: #4834d4;
    width: 70px;
    height: 70px !important;
    color: #fff !important;
    border-radius: 50% !important;
    line-height: 70px !important;
    padding: 0 !important;
    text-align: center !important;
    min-width: 70px !important;
    font-size: 24px !important;
}

h1, h2, h3, h4, h5, h6, dt {
    color: aqua !important;
}

p {
    color: gold !important;;
}
