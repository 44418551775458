/* ##### Service Area CSS ##### */

.HomeDemo2 .service_single_content {
    position: relative;
    z-index: 1;
    padding: 10px 60px 50px;
    border: 1px solid gold;
    background: rgba(0,0,0,0.1);
    border-radius: 0 20px;
    margin-bottom: 30px;
}

.HomeDemo2 .service_single_content .service_icon i {
    font-size: 30px;
    margin-bottom: 20px;
    color: #fff;
    display: block;
}
.HomeDemo2 .service_icon{
    margin-bottom: 20px
}
.HomeDemo2 .service_single_content h6 {
    margin-bottom: 15px;
    color: #fff;
    text-decoration: underline;
    font-weight: bold;
}
