
/* ##### 2.0 Header Area CSS ##### */

.HomeDemo2 .header-area {
    position: fixed;
    z-index: 5000;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.HomeDemo2 .header-area.sticky {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    background-color: #281b87;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    border-bottom: none;
}

.HomeDemo2 .dark.classy-nav-container {
    background-color: transparent;
}

.HomeDemo2 .classy-navbar {
    height: 90px;
    padding: 0;
}

.HomeDemo2 .classynav ul li a {
    text-transform: uppercase;
}

.HomeDemo2 .classynav ul li .megamenu li a {
    font-size: 80%;
}
